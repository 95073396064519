import React, { ChangeEvent } from 'react';
import { FormikErrors, FormikTouched } from 'formik';

export interface TextInputProps {
	title?: string;
	name: string;
	onChange: (e: ChangeEvent) => void;
	onBlur?: (e: ChangeEvent) => void;
	value: string | number;
	error?: string | FormikErrors<any>;
	touched?: boolean | FormikTouched<any>;
	onFocus?: any;
	placeholder?: string;
	readOnly?: boolean
	autocomplete?: boolean;
	autoFocus?: boolean
	className?: string;
	type?: "text" | "number" | "password" | "url" | "tel";
}

export function TextInput(props: TextInputProps) {
	const showErrors = !!props.error && props.touched;
	const showValid = !props.error && !props.readOnly && (props.value || props.touched );

	return (
		<React.Fragment>
			<input
				name={props.name}
				type={props.type ? props.type : 'text'}
				id={props.name}
				className={
					'form-control'
					+ (showErrors ? ' is-invalid' : '')
					+ (showValid ? ' is-valid' : '')
					+ ((props.className) ? ' ' + props.className : '')
				}
				value={props.value}
				onChange={props.onChange}
				onBlur={props.onBlur}
				placeholder={props.placeholder}
				onFocus={props.onFocus ? props.onFocus : () => {}}
				readOnly={!!props.readOnly}
				autoComplete={props.autocomplete === false ? 'off' : 'on'}
				autoFocus={props.autoFocus}
			/>
			<div className={"invalid-feedback " + (showErrors ? 'd-block' : 'd-none')}>
				{showErrors ? props.error : null}
			</div>
		</React.Fragment>
	);
}