import axios from "axios";
import IS_DEV from "./AppConstants";

const ApiUrl : string = (IS_DEV) ? 
    "https://7njrnvlm8k.execute-api.eu-central-1.amazonaws.com/develop/testkit" : 
    "https://rbwnvp4s70.execute-api.eu-central-1.amazonaws.com/consentry-raven/testkit"
//: "https://testkit.raven.dtact.consentry.com/testkit";

export interface TestkitFormModel {
    testkitId: string;
    bsn: string;
    passport: string;
    firstName: string;
    lastName: string;
    citizenship: string;
    mobilePhone: string;
    repeatMobilePhone: string;
    email: string;
    repeatEmail: string;
    acceptTerms: boolean;
    isInterestedCert: boolean;
}

/**
 * Validates if an ID is valid
 * @param id TestKit ID
 */
export const ValidateTestKitId = async (id: string) => {
    console.log("Testing ID:", id)
    let result : boolean = false;
    if(id && id.length > 10){
        result = await axios.get(ApiUrl + "/validate?testKitId=" + id)
        .then(res => {
            console.log("Is the testKitId valid?", res.data)
            return (res.data === true);
        })
    }
    return result;
}

/**
 * @param model TestkitForm values
 */
export const SubmitForm = async (model: TestkitFormModel, lang: string) : Promise<[number, TestkitFormModel]> => {
    //console.log("Posting ID:", model.testkitId)
    //console.log("Posting model:", model)
    const response = await axios.post(ApiUrl + "/submitWeb", {
        firstname: model.firstName,
        lastname: model.lastName,
        citizenship: model.citizenship,
        phonenumber: model.mobilePhone,
        email: model.email,
        ...(model.citizenship === "NL") && {bsn: model.bsn },
        passport_number: model.passport,
        language: lang,
        testKitId: model.testkitId,
        isInterestedCert: model.isInterestedCert
    })
    .then(res => {
        console.log("Submit response", res)
        return res;
    }).catch(err => {
        console.log(err)
        return err.response;
    });

    return [response.status, model];
}