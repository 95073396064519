import React from 'react';
import { TextInputProps, TextInput } from './textInput';


interface Props extends TextInputProps {
	helpText?: string;
}

export function LabeledTextInput(props: Props) {
	return (
		<div className="form-group">
			<label htmlFor={props.name}>{props.title}</label>
			<TextInput {...props} />
			{props.helpText && (
				<small className="form-text text-muted">{props.helpText}</small>
			)}
		</div>
	);
}