import React, { useMemo, useState } from 'react';
import logo from './assets/consentry-logo.svg';
import heroImage from './assets/consentry-hero-img.svg';
import './App.css';
import TestkitForm from './TestkitForm';
import LocalizedStrings from 'react-localization';
import { TestKitFormLabels } from './TestKitFormLabels';

function App() {
  // Localizsation
  const labels = useMemo(() => new LocalizedStrings(TestKitFormLabels), []);
  const [ lang, setLang ] = useState<string>("nl");
  labels.setLanguage(lang);
  const onUpdateLang = (newLang: string) => {
      setLang(newLang)
  }

  const LanguageSwitcher = () =>
  (
    <div className="languagePicker d-flex align-items-center">
      <button onClick={() => onUpdateLang("nl")} className={"btn btn-sm btn-link" + (lang === "nl" ? " text-muted" : "")}>Dutch</button>
      <span className="d-inline-block"> | </span>
      <button onClick={() => onUpdateLang("en")} className={"btn btn-sm btn-link" + (lang === "en" ? " text-muted" : "")}>English</button>
    </div>
  )

  return (
    <div className="AppWrap">
      <div className="App">
        <LanguageSwitcher />
        <header className="App-header">
          <img src={logo} className="logo" alt="Consentry" />
          <img src={heroImage} className="mt-4" alt="Consentry" />
        </header>
        <TestkitForm language={lang} labels={labels} />
      </div>
    </div>
  );
}

export default App;