import React from 'react';
import { Button } from './button';

interface Props {
	title: string;
	disabled: boolean;
	className?: string;
	loading?: boolean;
	saving?: boolean;
}

export function SubmitButton(props: Props) {
	return (
		<Button
			submit={true}
			disabled={props.disabled}
			className={props.className}>
			{(props.loading || props.saving) && (
				<React.Fragment>
					<span className="spinner-border spinner-border-sm mr-2" />
					{props.loading && 'Loading...'}
					{props.saving && 'Saving...'}
				</React.Fragment>
			)}
			{(!props.loading && !props.saving) && props.title}
		</Button>
	);
}