import React from 'react';

export interface Props {
	children?: React.ReactNode;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	className?: string;
	submit?: boolean;
	disabled?: boolean;
	title?: string;
	tabIndex?: number;
}

export function Button(props: Props) {
	return (
		<button
			disabled={props.disabled}
			type={props.submit ? 'submit' : 'button'}
			onClick={props.onClick ?? undefined}
			title={props.title}
			tabIndex={props.tabIndex}
			className={"btn " + props.className}
        >
            <React.Fragment>
                {props.children && (
                    <div className="btn-content">{props.children}</div>
                )}
            </React.Fragment>
		</button>
	);
}