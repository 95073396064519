import React, { useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SubmitButton } from './components/submitButton';
import { LabeledTextInput } from './components/labeledTextInput';
import { SubmitForm, TestkitFormModel, ValidateTestKitId } from './TestkitAPI';
import isValidBSN from './helpers/bsnValidator';
import { Button } from './components/button';
import IS_DEV from "./AppConstants";
import { LocalizedStrings } from 'react-localization';
import { Credits } from './components/credits';
import googlePlay from './assets/googlePlay.svg';
import appStore from './assets/appStore.svg';


interface FormProps {
    language: string;
    labels: LocalizedStrings<any>;
}

function TestkitForm(props: FormProps) {
    const { language , labels } = props;

    // Set language if changed
    React.useEffect(() => {
        labels.setLanguage(language);
    }, [language, labels])

    // Form state
    const [ formSubmitted, setFormSubmitted ] = useState<boolean>(false);
    const [ formSubmitValue, setFormSubmitValue ] = useState<TestkitFormModel>();
    const [ disclaimerOpen, setDisclaimerOpen] = useState<boolean>(false);

    const formRef = useRef<HTMLDivElement>(null)
    const executeScroll = () => formRef.current?.scrollIntoView();
    const [ formOpen, setFormOpen] = useState<boolean>(false);
    const toggleForm = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        setFormOpen(!formOpen)
        setTimeout(() => executeScroll(), 100)
    }

    const queryTestKitId = new URLSearchParams(window.location.search).get("kitId");

    const initVals : TestkitFormModel = {
        testkitId: queryTestKitId ?? '',
        bsn: IS_DEV ? '123456789' : '',
        passport:  IS_DEV ? '123456789' : '',
        firstName: IS_DEV ? 'John' : '',
        lastName: IS_DEV ? 'Johnson' : '',
        citizenship: 'NL',
        mobilePhone: IS_DEV ? '+31600000000' : '',
        repeatMobilePhone: IS_DEV ? '+31600000000' : '',
        email: IS_DEV ? 'john@example.com' : '',
        repeatEmail: IS_DEV ? 'john@example.com' : '',
        acceptTerms: false,
        isInterestedCert: false
    }

    const onSubmit = async (values: TestkitFormModel, actions: any) => {
        const [ statusCode, model ] = await SubmitForm(values, language);
        console.log("Code is:", statusCode)
        if(statusCode === 200){
            setFormSubmitted(true)
            setFormSubmitValue(model)
        }
        else if(statusCode === 409){
            console.log("409 error")
            actions.setSubmitting(false)
            window.alert("The Test Kit ID was already used.")
        }
        else {
            actions.setSubmitting(false)
            window.alert("An error occured. Please refresh and try again.")
        }
    }

    const ValidationSchema = Yup.object().shape({
        testkitId: Yup.string()
            .required(labels.formRequired)
            .min(10, labels.formInvalidLength)
            .test('validateTestKitIdFormat', labels.formTestKitIdInvalid, async (value) => {const result = await ValidateTestKitId(value ?? ""); return result; }),
        firstName: Yup.string()
            .required(labels.formRequired),
        lastName: Yup.string()
            .required(labels.formRequired),
        citizenship: Yup.string()
            .required(labels.formRequired),
        bsn: Yup.string().when('citizenship', {
            is: (citizenship: string) => citizenship === 'NL',
            then: Yup.string().required(labels.formRequired)
                .length(9, labels.formInvalidLength)
                .test('validateBsnFormat', labels.formBsnInvalid, (value) => {return isValidBSN(value ?? "")}),
            otherwise: Yup.string()
        }),
        passport: Yup.string()
            .required(labels.formRequired)
            .min(6, labels.formInvalidLength),
        mobilePhone: Yup.string()
            .required(labels.formRequired),
            //.matches(//^(\+?31|0)6-?(\d{8})/ , labels.formMobilePhoneInvalid), // 
        repeatMobilePhone: Yup.string()
            .required(labels.formRequired)
            .oneOf([Yup.ref('mobilePhone')], labels.formMobilePhoneMustMatch),
        email: Yup.string()
            .required(labels.formRequired)
            .email(labels.formEmailInvalid),
        repeatEmail: Yup.string()
            .required(labels.formRequired)
            .oneOf([Yup.ref('email')], labels.formEmailsMustMatch),
        isInterestedCert: Yup.bool(),
        acceptTerms: Yup.bool()
            .required(labels.formRequired)
            .oneOf([true], labels.formRequired),
    });

    return formSubmitted && formSubmitValue ?
    (<div className="text-center">
        <h1 className="h5 mb-2">{labels.successTitle}</h1>
        <p className="lead mt-2">{labels.formatString(labels.successText, formSubmitValue.mobilePhone, formSubmitValue.email)}</p>
        <Credits labels={labels} />
    </div>) :
    (
        <>
        <h1 className="h5 mb-2 text-center">{labels.pageTitle}</h1>
        <div className="app-download d-flex justify-content-around">
            <a href="https://apps.apple.com/us/app/consentry/id1523457091" target="_blank" rel="noreferrer">
                <img src={appStore} alt="Consentry - AppStore" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=me.digi.consentry&hl=en" target="_blank" rel="noreferrer">
                <img src={googlePlay} alt="Consentry - Google Play" />
            </a>
        </div>
        <p dangerouslySetInnerHTML={{ __html: labels.downloadText }} />
        <Credits labels={labels} />
        <div className="area-light">
            {labels.accessFormText} <a href="#form" onClick={(event) => toggleForm(event)}>{labels.accessFormButtonText}</a>
        </div>

        <div className="formWrap pt-3 mb-3" hidden={!formOpen} ref={formRef}>
            <div className="area-lighter">{labels.pageNotice}</div>
            <div className="area-yellow mt-3"><span dangerouslySetInnerHTML={{ __html: labels.certificateNotice }} /></div>
            <Formik
            initialValues={initVals}
            validationSchema={ValidationSchema}
            validateOnMount
            onSubmit={(values, actions) => onSubmit(values, actions)}>
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                isValid
            }) => (
                <Form className="App-form mt-3">
                    <LabeledTextInput
                        name="testkitId"
                        title={labels.testKitId}
                        placeholder={labels.testKitIdPlaceholder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.testkitId}
                        error={errors.testkitId}
                        touched={touched.testkitId}
                        autocomplete={false}
                        autoFocus={!queryTestKitId}
                    />

                    <div className="row">
                        <div className="col">
                            <LabeledTextInput
                                name="firstName"
                                title={labels.firstName}
                                placeholder={labels.firstNamePlaceholder}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                                error={errors.firstName}
                                touched={touched.firstName}
                                autoFocus={queryTestKitId !== null}
                            />
                        </div>
                        <div className="col">
                            <LabeledTextInput
                                name="lastName"
                                title={labels.lastName}
                                placeholder={labels.lastNamePlaceholder}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                                error={errors.lastName}
                                touched={touched.lastName}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label>{labels.citizenship}</label>
                        <div role="group" aria-labelledby="citizenship">
                            <div className="form-check form-check-inline">
                                <Field type="radio" name="citizenship" value="NL" id="C_NL" className="form-check-input" />
                                <label className="form-check-label" htmlFor="C_NL">{labels.citizenshipDutch}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <Field type="radio" name="citizenship" value="other" id="C_Other" className="form-check-input" />
                                <label className="form-check-label" htmlFor="C_Other">{labels.citizenshipOther}</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {values.citizenship === 'NL' && (
                        <div className="col">
                            <LabeledTextInput
                                name="bsn"
                                title={labels.bsn}
                                placeholder={labels.bsnPlaceholder}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bsn}
                                error={errors.bsn}
                                touched={touched.bsn}
                            />
                        </div>
                        )}
                        <div className="col">
                            <LabeledTextInput
                                name="passport"
                                title={labels.passport}
                                placeholder={labels.passportPlaceholder}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.passport}
                                error={errors.passport}
                                touched={touched.passport}
                            />
                        </div>
                    </div>

                    <LabeledTextInput
                        name="mobilePhone"
                        title={labels.phone}
                        placeholder={labels.phonePlaceholder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mobilePhone}
                        error={errors.mobilePhone}
                        touched={touched.mobilePhone}
                    />

                    <LabeledTextInput
                        name="repeatMobilePhone"
                        title={labels.confirmPhone}
                        placeholder={labels.phonePlaceholder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.repeatMobilePhone}
                        error={errors.repeatMobilePhone}
                        touched={touched.repeatMobilePhone}
                    />

                    <LabeledTextInput
                        name="email"
                        title={labels.email}
                        placeholder={labels.emailPlaceholder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                    />

                    <LabeledTextInput
                        name="repeatEmail"
                        title={labels.confirmEmail}
                        placeholder={labels.emailPlaceholder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.repeatEmail}
                        error={errors.repeatEmail}
                        touched={touched.repeatEmail}
                    />

                    <div id="disclaimer" className="form-group">
                        <label>Disclaimer</label>
                        <Button className="btn btn-secondary btn-block" onClick={() => setDisclaimerOpen(!disclaimerOpen)}>{disclaimerOpen ? "Hide" : "Open"} disclaimer</Button>
                        <div className={"collapse" + (disclaimerOpen ? ' show' : '')}>
                            <p className="mt-2">{labels.disclaimerText}</p>
                        </div>
                    </div>

                    <div className="form-check form-group">
                        <label className="form-check-label">
                            <Field type="checkbox" name="acceptTerms" className="form-check-input" checked={values.acceptTerms} />
                            <div dangerouslySetInnerHTML={{ __html: labels.acceptDisclaimer }} />
                        </label>
                        {errors.acceptTerms && (<div className="invalid-feedback d-block"><ErrorMessage name="acceptTerms" /></div>)}
                    </div>

                    <SubmitButton
                        title={labels.submit}
                        disabled={!isValid}
                        loading={isSubmitting}
                        className="btn-primary btn-lg btn-block" />
                </Form>
            )}
            </Formik>
        </div>
    </>
    );
}

export default TestkitForm;