import logo from '../assets/consentry-logo.svg';
import byHealthmark from '../assets/powered-by-healthmark.svg';
import byRIVM from '../assets/certified-by-RIVM.svg';
import byNEN7510 from '../assets/certified-by-NEN7510.svg';
import byMedmij from '../assets/certified-by-medmij.svg';
import { LocalizedStrings } from 'react-localization';

interface CreditsProps {
    labels: LocalizedStrings<any>;
}

export const Credits = (props: CreditsProps) => (
    <div className="row text-center">
    <div className="col-12 mt-2 mb-4 poweredBy">
        <h6>{props.labels.poweredBy}</h6>
        <div className="area-lighter d-flex justify-content-around">
            <img src={byHealthmark} alt="Healthmark" />
            <img src={logo} alt="Consentry" />
        </div>
    </div>
    <div className="col-12 mb-4 certifiedBy">
        <h6>{props.labels.certifiedBy}</h6>
        <div className="area-lighter d-flex justify-content-around">
            <img src={byRIVM} alt="Consentry" />
            <img src={byNEN7510} alt="Consentry" />
            <img src={byMedmij} alt="Consentry" />
        </div>
    </div>
</div>
)