// const generateBSN = () : string => {
//     let rnd, rndString, sum;
//     do {
//         rnd = Math.floor(Math.random() * 1000000000);
//         rndString = addLeadingZerosToBSN(String(rnd));
//         sum = getSumBSN(rndString);
//         console.log("BSN: "+rndString+", sum: "+sum+", mod: "+(sum%11) );
//     } while (sum % 11)
//     return rndString;
// }

const addLeadingZerosToBSN = (bsn: string) : string => {
	return ("000000000" + bsn).slice(-9);
}

const getSumBSN = (bsn: string) : number => {
  const a = parseInt(bsn[0])*9;
  const b = parseInt(bsn[1])*8;
  const c = parseInt(bsn[2])*7;
  const d = parseInt(bsn[3])*6;
  const e = parseInt(bsn[4])*5;
  const f = parseInt(bsn[5])*4;
  const g = parseInt(bsn[6])*3;
  const h = parseInt(bsn[7])*2;
  const i = parseInt(bsn[8])*-1;

  const sum = a+b+c+d+e+f+g+h+i;
  return sum;
}

const isValidBSN = (bsn: string) : boolean => {
  bsn = addLeadingZerosToBSN(bsn);
  return !(getSumBSN(bsn) % 11);
}

export default isValidBSN;