const TestKitFormLabels = {
    "en": {
        pageTitle: "Register your Test Kit to receive updates on your test results.",
        pageNotice: "In case of a positive test result, we are required to report this to the GGD. In order to do this, we must request the information below. Please fill in this information with care.",
        certificateNotice: `Please note that due to added security measures and to protect your private medical data, we cannot send you a test certificate if you use this web form. Instead please use the Consentry app which will provide you with a test certificate along with the appropriate privacy and security measures to protect your data.
        You may require a test certificate for border crossings, attending social gatherings or to be able to work. Please contact <a href="mailto:info@healthmark.nl">info@healthmark.nl</a> for more information.
        <strong>Please note that the information you enter below is not visible to us and will only be disclosed in case of a positive test result of which we are legally obliged to be reported to the GGD.</strong>`,
        formRequired: "Field is required",
        formInvalidLength: "Invalid length",
        formEmailInvalid: "Must be a real email",
        formEmailsMustMatch: "E-mail address must match",
        formMobilePhoneMustMatch: "Mobile phone must match",
        formMobilePhoneInvalid: "Please enter your mobile phone number",
        formTestKitIdInvalid: "The entered ID is not valid, please verify it is entered correctly",
        formBsnInvalid: "Please enter your BSN. You find your BSN on your passport/ID-card/drivers-license",
        // Fields:
        testKitId: "Test Kit ID",
        testKitIdPlaceholder: "Example: 1234ABCD-123ABC",
        phone: "Mobile phone number",
        confirmPhone: "Confirm mobile phone number",
        phonePlaceholder: "+31600000000",
        email: "E-mail",
        confirmEmail: "Confirm e-mail address",
        emailPlaceholder: "example@email.com",
        acceptDisclaimer: `By clicking <strong>Register</strong>, I confirm:
        <ul>
          <li>I have read the <strong>disclaimer</strong> and agree with the content.</li>
          <li>I have read and accept the <a href="https://consentry.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>.</li>
          <li>I hereby give permission to analyze the enclosed sample with PCR technology for the presence of SARS-CoV-2.</li>
          <li>In the case of a positive result, my contact details can be shared with the GGD.</li>
        </ul>`,
        bsn: "BSN",
        bsnPlaceholder: "123456789",
        downloadText: "<strong>Download Consentry for iOS and Android</strong> to safeguard your personal information and test results. Consentry is a highly secure personal data wallet that ensures your data privacy, which means no data will leave your device without your knowledge. Your results are always available when required.",
        accessFormText: "Don't have an iPhone or Android device?",
        accessFormButtonText: "Click here to complete the webform instead.", 
        poweredBy: "Powered By",
        certifiedBy: "Certified By",
        citizenship: "Citizenship",
        citizenshipDutch: "Dutch",
        citizenshipOther: "Other",
        passport: "Passport/ID no.",
        passportPlaceholder: "123456789",
        firstName: "First name",
        firstNamePlaceholder: "Your first name",
        lastName: "Last name",
        lastNamePlaceholder: "Your last name",
        submit: "Register",
        disclaimerText: "The PCR test technology used has been validated. However, there is always a small chance of a so-called false negative (you are infected, but the test does not indicate that) or false positive (you are not infected, but the test indicates that you are infected). In addition, there is also a small chance that the sample taken from you will not give an unambiguous result due to external causes. Consider, for example, recent oral use of medication, alcohol, mouthwash and / or other contaminants in the mouth, throat and nasal cavities or failure to correctly follow the instructions included in the accompanying manual, which includes, but is not exhaustive: do not rinse mouth, eat, drink, smoke and / or chew gum within 30 minutes before collecting saliva. A PCR test therefore never offers an absolute (100%) certainty on a (reliable) result. You cannot therefore derive any rights from the result of a test. The mentioned possible deviations in the determination of the result apply to all tests. Also the tests taken by the GGD and / or doctor or GP. This test taken therefore offers you an equal chance of a positive or negative result when compared with tests taken by the aforementioned GGD and / or doctor or general practitioner.",
        successTitle: "Your test kit has been successfully registered",
        successText: "As soon as the result of the analysis is available in the laboratory, the result will be communicated. In case of a negative result, you will receive this by SMS on {0} and by e-mail to {1}. If you don't see it in your inbox, check it in your Junk or Spam folder. We will contact you by phone in the event of a positive or ambiguous result."
    },
    "nl": {
        pageTitle: "Registreer je Test Kit om updates over je resultaat te kunnen ontvangen.",
        pageNotice: "In het geval van een positief testresultaat, zijn wij verplicht dit door te geven aan de GGD. Om dit te kunnen doen, moeten wij onderstaande informatie opvragen. Wilt u deze informatie alstublieft met zorg invullen.",
        certificateNotice: `Vanwege extra veiligheidsmaatregelen en om uw persoonlijke medische gegevens te beschermen, kunnen wij u geen testcertificaat sturen als u dit webformulier gebruikt. Gebruik in plaats daarvan de Consentry-app die u een testcertificaat bezorgt, samen met de beste privacy- en beveiligingsmaatregelen om uw gegevens te beschermen.
        U hebt mogelijk een testcertificaat nodig voor grensovergangen, het bijwonen van sociale bijeenkomsten of om te kunnen werken. Neem contact op met <a href="mailto:info@healthmark.nl">info@healthmark.nl</a> voor meer informatie.
        <strong>De informatie die u hieronder invult is voor ons niet zichtbaar en wordt alleen gebruikt voor het kunnen melden van positieve testresultaten bij de GGD waartoe wij wettelijk verplicht zijn.</strong>`,
        formRequired: "Verplicht veld",
        formInvalidLength: "Ongeldige lengte",
        formEmailInvalid: "Voer een geldig e-mail adres in",
        formEmailsMustMatch: "E-mail adres komt niet overeen",
        formMobilePhoneMustMatch: "Mobiele telefoon komt niet overeen",
        formMobilePhoneInvalid: "Voer een geldig mobiel nummer in",
        formTestKitIdInvalid: "Controleer of u de TestKit ID correct heeft ingevuld.",
        formBsnInvalid: "Voer hier een geldig BSN in. U vindt uw BSN op uw rijbewijs/paspoort/ID-kaart.",
        // Fields:
        testKitId: "Test Kit ID",
        testKitIdPlaceholder: "Voorbeeld: 1234ABCD-123ABC",
        phone: "Mobiele telefoon nummer",
        confirmPhone: "Bevestig mobiele telefoon nummer",
        phonePlaceholder: "+31600000000",
        email: "E-mail",
        confirmEmail: "Bevestig e-mail adres",
        emailPlaceholder: "voorbeeld@mail.nl",
        acceptDisclaimer: `Door op de knop <strong>Registreer</strong> te klikken bevestig ik het volgende:
        <ul>
          <li>Ik heb de <strong>disclaimer</strong> gelezen en ga akkoord met de inhoud.</li>
          <li>Ik heb de <a href="https://consentry.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> gelezen en ga akkoord met de inhoud.</li>
          <li>Ik geef toestemming om mijn testmonster te laten analyseren met PCR technologie op de aanwezigheid van SARS-CoV-2.</li>
          <li>In het geval van een positief resultaat geef ik toestemming mijn contactgegevens te delen met de GGD.</li>
        </ul>`,
        bsn: "BSN",
        bsnPlaceholder: "123456789",
        downloadText: "<strong>Download Consentry voor iOS en Android</strong> om je persoonlijke gegevens en testresultaten te beschermen. Consentry is een zeer veilige persoonlijke data omgeving die de data privacy borgt, wat betekent dat er geen gegevens uw apparaat verlaten zonder uw medeweten. Testresultaten zijn altijd beschikbaar wanneer dat nodig is.",
        accessFormText: "Heb je geen iPhone of Android toestel?",
        accessFormButtonText: "Click hier om het webformulier in te vullen.", 
        poweredBy: "Powered By",
        certifiedBy: "Certified By",
        citizenship: "Nationaliteit",
        citizenshipDutch: "Nederlandse",
        citizenshipOther: "Anders",
        passport: "Paspoort/ID nummer.",
        passportPlaceholder: "123456789",
        firstName: "Voornaam",
        firstNamePlaceholder: "Uw voornaam",
        lastName: "Achternaam",
        lastNamePlaceholder: "Uw achternaam",
        submit: "Registreer",
        disclaimerText: "De gehanteerde PCR test technologie is gevalideerd. Er is echter altijd een kleine kans op een zogenaamd vals-negatieve (u bent wel besmet, maar de test wijst dat niet uit) of vals-positieve (u bent niet besmet, maar de test indiceert uit dat u wel besmet bent) uitslag. Daarnaast bestaat er een eveneens kleine kans dat het bij u afgenomen monster niet een eenduidig resultaat geeft door externe oorzaken. Denk daarbij bijvoorbeeld aan recent oraal gebruik van medicatie, alcohol, mondspoelmiddelen en/of andere verontreinigingen in de mond-, keel- en neusholten of het niet op de juiste wijze uitvoeren van de instructies opgenomen in de bijgeleverde handleiding, waaronder onder andere, maar niet uitputtend, valt: niet de mond spoelen, binnen de 30 minuten voor speekselafname eten, drinken, roken en/of kauwgom kauwen. Een PCR test biedt derhalve nooit een absolute (100%) zekerheid op een (betrouwbaar) resultaat. Aan de uitslag van een test kunt u dan ook geen rechten ontlenen. Genoemde mogelijke afwijkingen bij het tot stand komen van het resultaat gelden voor alle testen. Ook de testen afgenomen door GGD en/of (huis)arts. Deze bij u afgenomen test biedt u dus een gelijke kans op een positieve of negatieve uitslag wanneer vergeleken met testen die afgenomen zijn door voornoemde GGD en/of (huis)arts.",
        successTitle: "Uw testkit is succesvol geregistreerd",
        successText: "Zodra het resultaat van de analyse in het laboratorium beschikbaar is, wordt het resultaat bekend gemaakt. Bij een negatieve uitslag ontvangt u deze per SMS op {0} en per e-mail op {1}. Als u het niet in uw Postvak IN ziet, controleer het dan in uw map Ongewenste e-mail of Spam. Bij een positieve of niet eenduidige uitslag nemen wij telefonisch contact met u op."
    }
};
export {TestKitFormLabels}